<div [ngStyle]="{'display': isOpen ? 'block' : 'none'}">
    <form [formGroup]='numberIntervalPicker'>
        <app-form-field class="mb-0">
            <app-text-field
                type="number"
                formControlName="from"
                placeholder="common.please_select"
                [tippy]="hintText | translate"
                [suffixTemplate]="suffixTemplate"
                variation="hint"
                [isEnabled]="!!hintText"
                [lazy]="true"
                [isHintShown]="false">
            </app-text-field>
        </app-form-field>

        <app-form-field class="mb-0">
            <app-text-field
                type="number"
                formControlName="to"
                placeholder="common.please_select"
                [tippy]="hintText | translate"
                [suffixTemplate]="suffixTemplate"
                variation="hint"
                [isEnabled]="!!hintText"
                [lazy]="true"
                [isHintShown]="false">
            </app-text-field>
        </app-form-field>
    </form>
</div>

<ng-template #suffixTemplate>
    <span>{{ suffix }}</span>
</ng-template>
