import { ComponentFactoryResolver, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { HotToastRef } from '@ngneat/hot-toast/lib/hot-toast-ref';
import { ToastBaseComponent } from '@shared/modules/toast/components/toast-base/toast-base.component';
import { CreateHotToastRef, ToastOptions } from '@ngneat/hot-toast/lib/hot-toast.model';
import { defaultToastConfig, toastTypeIconMap } from '@config/toast.config';
import { SimpleToastMessageComponent } from '@shared/modules/toast/components/simple-toast-message/simple-toast-message.component';
import { ToastType } from '@shared/modules/toast/classes/ToastType';

interface ToastComponentResolverProp {
  component: Type<ToastBaseComponent>;
  type: ToastType;
  message?: string | string[];
  title?: string;
  messagePrefix?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastTypes = ToastType;

  constructor(
    private toast: HotToastService,
    private cfr: ComponentFactoryResolver, // NOSONAR
    private injector: Injector
  ) {}

  showSuccess(message: string, options?: ToastOptions<unknown>): CreateHotToastRef<unknown> {
    return this.openSimpleToast(
      {
        component: SimpleToastMessageComponent,
        type: this.toastTypes.Success,
        message,
      },
      options
    );
  }

  showError(
    message: string,
    options?: ToastOptions<unknown>,
    title?: string
  ): CreateHotToastRef<unknown> {
    return this.openSimpleToast(
      {
        component: SimpleToastMessageComponent,
        type: this.toastTypes.Error,
        message,
        title,
      },
      options
    );
  }

  showInfo(
    message: string | string[],
    options?: ToastOptions<unknown>,
    title?: string,
    messagePrefix?: string
  ): CreateHotToastRef<unknown> {
    return this.openSimpleToast(
      {
        component: SimpleToastMessageComponent,
        type: this.toastTypes.Info,
        message,
        title,
        messagePrefix,
      },
      options
    );
  }

  removeToast(id: string): void {
    this.toast.close(id);
  }

  private openSimpleToast(
    props: ToastComponentResolverProp,
    options?: ToastOptions<unknown>
  ): CreateHotToastRef<unknown> {
    const { component, message, title, type, messagePrefix } = props;
    return this.toast.show(
      this.resolveToastComponentTemplate({
        component,
        message,
        type,
        title,
        messagePrefix,
      }),
      options || defaultToastConfig
    );
  }

  private resolveToastComponentTemplate(
    props: ToastComponentResolverProp
  ): TemplateRef<HotToastRef> {
    const { component, message, messagePrefix, title, type } = props;
    const factory = this.cfr.resolveComponentFactory(component);
    const createdComponent = factory.create(this.injector);
    if (type >= 0) {
      createdComponent.instance.iconUrl = toastTypeIconMap[type];
    }
    createdComponent.instance.title = title;
    createdComponent.instance.message = message;
    createdComponent.instance.messagePrefix = messagePrefix;
    return createdComponent.instance.toastTemplate;
  }
}
