import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AppConstants } from '@config/app.constant';

export class RegexValidator {
  constructor(
    private regex: RegExp,
    private control: AbstractControl,
    private controlName: string,
    private errorMessage: string,
    private errorType: string = AppConstants.inputFieldFormatKey,
    private isRequired?: boolean
  ) {}

  validate(): ValidationErrors {
    const controlValue = this.control?.value as string;
    const value = controlValue !== null && controlValue !== undefined ? String(controlValue) : '';

    if (!this.isRequired && !value) {
      return null;
    }

    const isValid = !!value.match(this.regex);

    return isValid
      ? null
      : {
          [this.errorType]: {
            [this.controlName]: this.errorMessage,
          },
        };
  }
}
