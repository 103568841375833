<div class="filter-cards-wrapper">
    <div class="d-flex justify-content-between mt-5px mb-5px">
        <p class="basic-white-bold-text">{{'side_filter.filter_title' | translate}}</p>
        <p class="delete-text basic-white-bold-text"
           (click)="resetFilter.emit()">{{'side_filter.delete_button' | translate}}
            <span *ngIf="numberOfActiveFilter > 0">({{numberOfActiveFilter}})</span>
        </p>
    </div>

    <div *ngFor="let filter of transformedFilters" [ngSwitch]="filter.data">
        <app-date-filtering
            *ngSwitchCase="filterTypes.DatePicker"
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-date-filtering>

        <app-truefalse-filtering
            *ngSwitchCase="filterTypes.TrueFalse"
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-truefalse-filtering>

        <app-text-number-filtering
            *ngSwitchCase="filterTypes.TextField"
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-text-number-filtering>

        <app-text-number-filtering
            *ngSwitchCase="filterTypes.NumberField"
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-text-number-filtering>

        <app-number-filtering
            *ngSwitchCase="filterTypes.NumberInterval"
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            (filterValueChange)="setFilter.emit($event)">
        </app-number-filtering>

        <app-dropdown-filtering
            *ngSwitchDefault
            [title]="filter.name ?? (translateKey + filter.translateKey)"
            [filterKey]="filter.filterKey ?? filter.translateKey"
            [data]="filter.data"
            [singleDropdown]="filter.singleDropdown || false"
            [withInteger]="filter.withInteger || false"
            [withDate]="filter.withDate || false"
            [withCountry]="filter.withCountry"
            [countries]="filter.withCountry ? countries : []"
            [dropdownConfig]="{
              listType: filter.listType,
              listUrl: filter.listUrl,
              listQueryParamName: filter.listQueryParamName,
              additionalIds: filter.additionalIds
            }"
            (filterValueChange)="setFilter.emit($event)">
        </app-dropdown-filtering>
    </div>
</div>
