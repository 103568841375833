<ng-template let-item>
    <app-status-selection
        class="d-block w-100"
        [positionId]="item.id"
        [positionType]="item?.type"
        [userPositionType]="item?.userPosition?.type"
        [editable]="isEnabled && item?.userPosition?.editable"
        [status]="item?.userPosition?.status"
        [feorNumber]="item?.feorNumber"
        [statusOption]="item?.userPosition?.statusOption"
        [userRoles]="userRoles"
        [hasTermsAndConditions]="hasTermsAndConditions"
        [statuses]="statuses">
    </app-status-selection>
</ng-template>
