<ng-template let-row="row" let-value="value">
    <div class="d-flex align-items-center position-relative">
        <div class="logo mr-10px position-relative">
            <div *ngIf="value.type === ATTACHMENT_TYPE.Contact" class="position-absolute badge-container">
                <div class="status-badge">M</div>
            </div>

            <img *ngIf="value.textValue && value.imageUrl"
                 class="logo-img w-100 h-100"
                 [ngClass]="{ 'circle-image': value.isCircleImage }"
                 [src]="value.imageUrl"
                 [appImgFallback]="value.placeholderImg"
                 alt="Partner Logo">
        </div>
        <span>{{ value.textValue }}</span>
    </div>
</ng-template>
