import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { CardFilteringBaseComponent } from '@shared/modules/filter-components/components/card-filtering-base/card-filtering-base.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilteringService } from '@shared/modules/filter-components/services/filtering.service';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { NumberInterval } from '@shared/modules/filter-components/components/number-filtering/classes/NumberInterval';
import { NumberIntervalPickerValue } from '@shared/modules/filter-components/components/number-filtering/classes/NumberIntervalPickerValue';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-number-filtering',
  templateUrl: './number-filtering.component.html',
  styleUrls: ['./number-filtering.component.scss'],
})
export class NumberFilteringComponent
  extends CardFilteringBaseComponent
  implements OnInit, OnDestroy {
  numberIntervalPicker: FormGroup;

  constructor(
    readonly eRef: ElementRef<HTMLElement>,
    readonly filteringService: FilteringService,
    readonly translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    super(eRef, filteringService, translate);
    this.isChecked = false;
    this.isDisabled = true;

    this.numberIntervalPicker = this.initForm();
  }

  ngOnInit() {
    this.setupFormValueListeners();

    this.listenSavedFilterChange(this.filterKey)
      .pipe(untilDestroyed(this))
      .subscribe((items: unknown) => {
        this.setValueForControls(items as NumberInterval);
      });

    this.listenFilterResetAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetFormControls();
      });

    return super.ngOnInit();
  }

  onCheckboxChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.isOpen = false;

    if (isChecked) {
      this.filterValueChange.emit({
        [this.filterKey]: this.numberIntervalPicker.getRawValue() as NumberIntervalPickerValue,
      });
    } else {
      this.filterValueChange.emit({ [this.filterKey]: undefined });
    }
  }

  deleteItem() {
    this.resetFormControls();
  }

  private initForm(): FormGroup {
    return new FormGroup(
      {
        from: new FormControl(null, [FormCustomValidators.numberValidator()]),
        to: new FormControl(null, [FormCustomValidators.numberValidator()]),
      },
      {
        validators: [FormCustomValidators.rangeValidator('from', 'to')],
      }
    );
  }

  private setupFormValueListeners(): void {
    this.numberIntervalPicker.statusChanges
      .pipe(untilDestroyed(this), debounceTime(300))
      .subscribe(() => this.checkAndTriggerFilter());

    this.checkAndTriggerFilter();
  }

  private checkAndTriggerFilter(): void {
    const fromValue = this.numberIntervalPicker.get('from').value as number;
    const toValue = this.numberIntervalPicker.get('to').value as number;

    const hasValue = fromValue != null || toValue != null;
    const isFormValid = this.numberIntervalPicker.valid;

    if (hasValue && isFormValid) {
      this.isDisabled = false;
      this.isChecked = true;

      this.filterValueChange.emit({
        [this.filterKey]: this.numberIntervalPicker.getRawValue() as NumberIntervalPickerValue,
      });
    } else if (!hasValue) {
      this.isDisabled = true;
      this.isChecked = false;

      this.filterValueChange.emit({ [this.filterKey]: undefined });
    } else {
      this.isDisabled = true;
    }
  }

  private setValueForControls(valueOfControl: NumberInterval | undefined): void {
    if (typeof valueOfControl !== 'undefined') {
      this.numberIntervalPicker.patchValue(valueOfControl);
    } else {
      this.resetFormControls();
    }
  }

  private resetFormControls(): void {
    this.numberIntervalPicker.reset();
    this.isChecked = false;
    this.isDisabled = true;
  }

  ngOnDestroy() {
    this.resetFormControls();
    return super.ngOnDestroy();
  }
}
