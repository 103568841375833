<ng-template #toastTemplate let-toast>
    <app-toast-base [toastRef]="toast" [iconUrl]="iconUrl">
        <div *ngIf="title" class="font-weight-bold">
            {{ title | translate }}
        </div>

        <ng-container *ngIf="!isMessageArray(); else messageList">
            {{ message | translate }}
        </ng-container>

        <ng-template #messageList>
            <ul class="pl-4 mb-0">
                <li *ngFor="let msg of message">
                    {{ (messagePrefix ? messagePrefix + '.' + msg : msg) | translate }}
                </li>
            </ul>
        </ng-template>
    </app-toast-base>
</ng-template>
