import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '@config/app.constant';
import { EndpointsConfig } from '@config/endpoints.config';
import { CandidateBulkUpdateState } from '@pages/candidates/classes/CandidateBulkUpdateState';
import { CandidateBulkUpdateDto } from '@pages/candidates/classes/CandidateBulkUpdateDto';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { CandidateListItem } from '@pages/candidates/classes/CandidateListItem';
import { CandidatePosition } from '@pages/candidates/classes/CandidatePosition';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';
import { NormalizedCandidateDto } from '@pages/candidates/classes/NormalizedCandidateDto';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { BulkEditResponse } from '@shared/classes/BulkEditResponse';
import { ListData, ListTotalCount } from '@shared/classes/ListData';
import { HistoryMessage } from '@shared/modules/history-message/classes/HistoryMessage';
import { HttpService } from '@shared/modules/http/http.service';
import { ATSConfigService } from '@shared/services/ats-config.service';
import {
  HistoryMessageInputTypes,
  HistoryMessageType,
} from '@shared/modules/history-message/classes/HistoryMessageType';
import { getPaginatedParams } from '@shared/utils/get-page-per-page.util';
import { CardFilterInterface } from '@shared/modules/filter-components/classes/card-filter.interface';
import { HttpResponse } from '@angular/common/http';
import { CandidateCustomField } from '@pages/candidates/classes/CandidateCustomField';
import { UserPositionType } from '@pages/positions/UserPositionType';

@Injectable({
  providedIn: 'root',
})
export class CandidateApiService {
  constructor(private http: HttpService, private atsConfigService: ATSConfigService) {}

  getCandidates(
    page: string,
    perPage: string,
    filter?: string
  ): Observable<ListData<CandidateListItem>> {
    const params: { [k: string]: string } = {
      page,
      perPage,
    };
    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.candidates, params);
  }

  getCandidateFirstLetters(): Observable<string[]> {
    return this.http.get(EndpointsConfig.candidatesFirstLetters);
  }

  getCandidateCustomFields(): Observable<CandidateCustomField[]> {
    return this.http.get(EndpointsConfig.candidateCustomFields);
  }

  getCandidateFilters(): Observable<{ [key: string]: CardFilterInterface }> {
    return this.http.get(EndpointsConfig.candidatesFilters);
  }

  getCandidateTotalCount(filter?: string): Observable<ListTotalCount> {
    const params: { [k: string]: string } = {};

    if (filter) {
      params.filter = filter;
    }
    return this.http.get(EndpointsConfig.candidatesTotalCount, params);
  }

  createCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.post(EndpointsConfig.candidates, this.normalizeCandidateDto(candidateDto));
  }

  addDeleteTagToCandidate(candidateId: number): Observable<void> {
    return this.http.post(EndpointsConfig.addSystemTag(candidateId), {
      tagName: 'delete',
    });
  }

  convertToCandidate(candidateId: number): Observable<void> {
    return this.http.put(EndpointsConfig.convertToCandidateById(candidateId), {});
  }

  updateCandidate(candidateDto: CandidateDto): Observable<CandidateDetail> {
    return this.http.put(
      EndpointsConfig.candidatesById(candidateDto.id),
      this.normalizeCandidateDto(candidateDto)
    );
  }

  getCandidateById(id: number): Observable<CandidateDetail> {
    return this.http.get(EndpointsConfig.candidatesById(id));
  }

  getDocuments(id: number): Observable<CandidateDocument[]> {
    return this.http.get(EndpointsConfig.candidateDocuments(id));
  }

  uploadDocument(candidateId: number, formData: FormData): Observable<CandidateDocument> {
    return this.http.post(EndpointsConfig.candidateDocuments(candidateId), formData);
  }

  getHistory(
    candidateId: number,
    page = 1,
    type?: HistoryMessageType
  ): Observable<ListData<HistoryMessage>> {
    return this.http.get(
      EndpointsConfig.candidateHistory(candidateId),
      getPaginatedParams(page, AppConstants.historyMessagesPerPage, type)
    );
  }

  createHistoryMessage(
    candidateId: number,
    data: { message: string; type?: HistoryMessageInputTypes }
  ): Observable<HistoryMessage> {
    return this.http.post(EndpointsConfig.candidateHistory(candidateId), data);
  }

  updateHistoryMessage(
    candidateId: number,
    historyId: number,
    message: string
  ): Observable<HistoryMessage> {
    return this.http.put(EndpointsConfig.candidateHistoryWithId(candidateId, historyId), {
      message,
    });
  }

  deleteHistoryMessage(candidateId: number, historyId: number): Observable<unknown> {
    return this.http.delete(EndpointsConfig.candidateHistoryWithId(candidateId, historyId));
  }

  getCandidatePositions(
    candidateId: number,
    page = 1,
    perPage = AppConstants.cardTableRowsPerPage
  ): Observable<ListData<CandidatePosition>> {
    return this.http.get(
      EndpointsConfig.candidatePosition(candidateId),
      getPaginatedParams(page, perPage)
    );
  }

  getAllPositionsAttachedToCandidate(candidateId: number): Observable<CandidatePositionMinimal[]> {
    return this.http.get(EndpointsConfig.candidateAllPositions(candidateId));
  }

  bulkUpdateCandidate(candidateBulkUpdate: CandidateBulkUpdateState): Observable<BulkEditResponse> {
    return this.http.put(
      EndpointsConfig.candidatesBulkEdit,
      this.normalizeCandidateBulkEdit(candidateBulkUpdate)
    );
  }

  attachToPosition(
    candidateId: number,
    positionId: number,
    type: UserPositionType
  ): Observable<PositionDetail> {
    return this.http.post(EndpointsConfig.candidatePositionAttach(candidateId), {
      positionId,
      type,
    });
  }

  detachPosition(candidateId: number, positionId: number): Observable<void> {
    return this.http.delete(EndpointsConfig.candidatePositionDetach(candidateId, positionId));
  }

  setCandidateProfileReadStatus(candidateId: number): Observable<CandidateDetail> {
    return this.http.put(EndpointsConfig.candidateSetProfileReadStatus(candidateId), {});
  }

  private normalizeCandidateBulkEdit(
    candidate: CandidateBulkUpdateState
  ): Partial<CandidateBulkUpdateDto> {
    return {
      candidateIds: candidate.candidateIds,
      membershipEndDate: candidate?.membershipEndDate?.name || undefined,
      statusId: +candidate?.statusId?.id || undefined,
      statusOptionId: +candidate?.statusOptionId?.id || undefined,
      positionId: +candidate?.positionId?.id || undefined,
      subPositionId: +candidate?.subPositionId?.id || undefined,
      membershipStartDate: candidate?.membershipStartDate?.name || undefined,
      feorNumber: candidate?.feorNumber?.name || undefined,
      guarantee: candidate?.guarantee?.name ?? undefined,
    };
  }

  exportCandidateTable(filter: string): Observable<HttpResponse<Blob>> {
    const params = filter ? { filter } : null;

    return this.http.getFile(EndpointsConfig.candidatesExport, params);
  }

  exportCandidateTableEmail(filter: string): Observable<void> {
    const params = filter ? { filter } : null;

    return this.http.get(EndpointsConfig.candidatesExportEmail, params);
  }

  normalizeCandidateDto(candidateDto: CandidateDto): NormalizedCandidateDto {
    const normalized: NormalizedCandidateDto = {
      ...candidateDto,
      cityPreferences: [],
    };

    if (!normalized.id) {
      delete normalized.id;
    }

    if (!normalized.profile.id) {
      delete normalized.profile.id;
    }

    if (typeof normalized.profile.providerType.id === 'string') {
      normalized.profile.providerType.id = null;
    }

    if (!this.atsConfigService.isStudentATS) {
      normalized.profile.parents = null;
    }

    normalized.languages = candidateDto.languages.filter((language) => {
      return !!language.languageId;
    });

    normalized.cityPreferences = candidateDto.cityPreferences
      .map((city) => city.id)
      .filter((value) => !!value);

    return normalized;
  }
}
