export enum BoardColumnSlugs {
  PartnerApplication = 'partner_application',
  Offer = 'offer',
  NameCheck = 'name_check',
  InProgress = 'in_progress',
  Outsourced = 'outsourced',
  Admitted = 'admitted',
  Denied = 'denied',
  Applied = 'applied',
  Contacted = 'contacted',
  NotAvailablePhone = 'not_available_phone',
  DataReconciliation = 'data_reconciliation',
  Interview = 'interview',
  PartnerAdmission = 'partner_admission',
}
