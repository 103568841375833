import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICustomCellComponent } from '@shared/modules/data-table/classes/ICustomCellComponent';
import { UserPositionType } from '@pages/positions/UserPositionType';

@Component({
  selector: 'app-small-image-datatable-cell',
  templateUrl: './small-image-datatable-cell.component.html',
  styleUrls: ['./small-image-datatable-cell.component.scss'],
})
export class SmallImageDatatableCellComponent implements ICustomCellComponent {
  @ViewChild(TemplateRef, { static: true }) cellTemplate: TemplateRef<unknown>;

  protected readonly ATTACHMENT_TYPE = UserPositionType;
}
