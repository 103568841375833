<div class="card-filtering-container">
    <div class="d-flex align-items-center">
        <div class="d-inline-flex">
            <app-checkbox
                checkboxColor="red"
                [formControl]="checkBox"
                [checked]="isChecked"
                [isDisabled]="isDisabled"
                (isChecked)="onCheckboxChanged($event)">
            </app-checkbox>
            <p class="card-filter-title">{{title | translate}}</p>
        </div>
    </div>

    <app-number-interval-picker [isOpen]="isOpen" [numberIntervalPicker]="numberIntervalPicker"></app-number-interval-picker>
</div>
