import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-number-interval-picker',
  templateUrl: './number-interval-picker.component.html',
  styleUrls: ['./number-interval-picker.component.scss'],
})
export class NumberIntervalPickerComponent {
  @Input() isOpen: boolean;
  @Input() numberIntervalPicker: FormGroup;
  @Input() hintText: string;
  @Input() suffix: string = 'Ft';

  deleteItem() {
    this.numberIntervalPicker.reset();
  }
}
