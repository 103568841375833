import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormCustomValidators } from '@shared/utils/form/form-custom-validators.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { OutsourcedModalData } from '@shared/modules/modals/position-modals/outsourced-modal/classes/OutsourcedModalConfig';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { take } from 'rxjs/operators';
import { PositionTypes } from '@pages/positions/classes/PositionTypes';

@UntilDestroy()
@Component({
  selector: 'app-labor-hire-outsourced-modal',
  templateUrl: './outsourced-modal.component.html',
  styleUrls: ['./outsourced-modal.component.scss'],
})
export class OutsourcedModalComponent
  extends MatModalBaseComponent<OutsourcedModalData>
  implements OnInit, OnDestroy {
  form: FormGroup;
  maxDate = new Date();
  partnerIdControl = new FormControl(null, [Validators.required]);
  projectIdControl = new FormControl(null, [Validators.required]);

  readonly POSITION_TYPE = PositionTypes;

  constructor(
    private positionState: PositionStateService,
    private formBuilder: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.primaryButtonDisabled = true;

    this.initForm();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.primaryButtonDisabled = this.form.invalid;
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      feorNumber: [
        this.data?.feorNumber || null,
        [Validators.required, FormCustomValidators.feorNumberValidator()],
      ],
      membershipStartDate: [null, [Validators.required]],
      subPositionId: [null, [Validators.required]],
    });

    if (this.data.positionType === PositionTypes.Outsourced) {
      this.form.addControl('guarantee', new FormControl(null, [Validators.required]));
    }
  }

  confirm() {
    this.primaryButtonDisabled = true;

    this.data
      .confirmFunction(
        this.data.positionId,
        {
          feorNumber: this.form.get('feorNumber').value as string,
          membershipStartDate: this.form.get('membershipStartDate').value as string,
          subPositionId: this.form.get('subPositionId').value as number,
          guarantee: this.getGuaranteeValue(),
        },
        this.data.candidateId,
        this.data.userRoles
      )
      .pipe(take(1))
      .subscribe(this.handleConfirmActionResponse.bind(this));
  }

  private getGuaranteeValue() {
    const guaranteeControl = this.form.get('guarantee');
    return guaranteeControl && this.data.positionType === PositionTypes.Outsourced
      ? (guaranteeControl.value as boolean)
      : null;
  }

  cancel() {
    if (this.data.cancelFunction) {
      this.data
        .cancelFunction()
        .pipe(take(1))
        .subscribe(() => {
          this.closeModal();
        });
    } else {
      this.closeModal();
    }
  }

  ngOnDestroy() {
    this.positionState.setState({ selectedKanbanBoardColumn: null });
    return super.ngOnDestroy();
  }
}
