import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { CandidateDto } from '@pages/candidates/classes/CandidateDto';
import { CandidateListItem } from '@pages/candidates/classes/CandidateListItem';
import { CandidatePositionMinimal } from '@pages/candidates/classes/CandidatePositionMinimal';
import { CandidateApiService } from '@pages/candidates/services/base/candidate-api.service';
import { createEmptyListData, ListData, ListTotalCount } from '@shared/classes/ListData';
import { HeaderService } from '@shared/modules/header/services/header.service';
import { ToastService } from '@shared/modules/toast/services/toast.service';
import { encodeBase64 } from '@shared/utils/encrypt.util';
import { getErrorTranslationKey, hasCommonError } from '@shared/utils/error-keys.util';
import { getGeneralMessage } from '@shared/utils/generate-general-toast-message.util';
import { removeBlankAttributes } from '@shared/utils/remove-blank-attributes.util';
import {
  addValidationErrorsToForm,
  parseBackendValidationErrors,
} from '@shared/utils/validaiton-error-parser.util';
import { EndpointsConfig } from '@config/endpoints.config';
import { environment } from '@environments/environment';
import { CandidateHistoryService } from '@pages/candidates/services/candidate-history.service';
import { MoveOrAddPositionDto } from '@pages/positions/classes/board/MoveOrAddPositionDto';
import { CandidatePosition } from '@pages/candidates/classes/CandidatePosition';
import { CandidatePositionCardTableRow } from '@pages/candidates/classes/CandidatePositionCardTableRow';
import { PositionDetail } from '@pages/positions/classes/PositionDetail';
import { head } from 'lodash-es';
import { Specialization } from '@pages/candidates/classes/Specialization';
import * as moment from 'moment/moment';
import { AppConstants } from '@config/app.constant';
import { PositionKanbanBoardApiService } from '@pages/positions/services/candidates/base/position-kanban-board-api.service';
import { CardTableService } from '@shared/modules/card-table/services/card-table.service';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { CandidateState } from '@shared/modules/state-manager/state/candidates/candidate.state';
import { PositionStateService } from '@pages/positions/services/details/base/position-state.service';
import { BoardCandidate } from '@pages/positions/classes/board/BoardCandidate';
import { MessageErrorResponse } from '@shared/classes/common/HttpErrorResponse';
import { CandidateDocument } from '@pages/candidates/classes/CandidateDocument';
import { getModalTypeUtil } from '@pages/candidates/utils/get-modal-type.util';
import { MultiUserRole } from '@shared/modules/auth/classes/MultiUserRole';
import { UpdateFunctionType } from '@pages/candidates/classes/UpdateFunctionType';
import { TablePageService } from '@shared/classes/table/TablePageService';
import { ModalType } from '@shared/classes/ModalType';
import { PhoneContactListItem } from '@pages/phone-contacts/classes/PhoneContactListItem';
import { RoleImageData } from '@shared/modules/data-table/classes/RoleImageData';
import { roleImagesConfig } from '@pages/candidates/config/candidate-role-image.config';
import { UserRole } from '@shared/modules/auth/classes/UserRole';
import { KanbanBoardTableItem } from '@pages/positions/classes/board/table/KanbanBoardTableItem';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { UserPositionType } from '@pages/positions/UserPositionType';
import { UserPositionTypeOption } from '@pages/positions/classes/UserPositionTypeOption';

@Injectable({
  providedIn: 'root',
})
export class CandidatesService extends TablePageService {
  constructor(
    private candidateStateService: CandidateStateService,
    private candidateApiService: CandidateApiService,
    private candidateHistoryService: CandidateHistoryService,
    private toast: ToastService,
    private headerService: HeaderService,
    private cardTableService: CardTableService,
    private candidateBoardApiService: PositionKanbanBoardApiService,
    private positionState: PositionStateService
  ) {
    super();
  }

  private getStateSnapshot() {
    return this.candidateStateService.getStateSnapshot();
  }

  private setState(stateSlice: Partial<CandidateState>) {
    return this.candidateStateService.setState(stateSlice);
  }

  getCandidates(
    page: string,
    perPage: string,
    base64EncodedFilter?: string
  ): Observable<ListData<CandidateListItem>> {
    return this.candidateApiService.getCandidates(page, perPage, base64EncodedFilter).pipe(
      catchError(() => {
        return of(createEmptyListData<CandidateListItem>());
      })
    );
  }

  getTotalCount(base64EncodedFilter?: string): Observable<ListTotalCount> {
    return this.candidateApiService.getCandidateTotalCount(base64EncodedFilter);
  }

  callCreateCandidate(
    candidateDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.candidateApiService
      .createCandidate(candidateDto)
      .pipe(this.handleCandidateSaveSideEffects(form, 'candidates.create_candidate', true));
  }

  callUpdateCandidate(
    candidateDto: CandidateDto,
    form: FormGroup
  ): Observable<CandidateDetail | HttpErrorResponse> {
    return this.candidateApiService.updateCandidate(candidateDto).pipe(
      tap((candidateDetail: CandidateDetail) => {
        this.refreshCandidateDetail(candidateDto.id, candidateDetail);
      }),
      this.handleCandidateSaveSideEffects(form, 'candidates.modify_candidate')
    );
  }

  handleCandidateSaveSideEffects(form: FormGroup, toastText: string, isCreateMode = false) {
    return (source: Observable<CandidateDetail>): Observable<CandidateDetail | HttpErrorResponse> =>
      source.pipe(
        tap((candidate: CandidateDetail) => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));

          if (isCreateMode && !this.getStateSnapshot().candidateModalOpenedFromPosition) {
            window.open(`${EndpointsConfig.candidates}/${candidate.id}`, '_blank');
          }
        }),
        catchError((err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            addValidationErrorsToForm(form, parseBackendValidationErrors(err));
          }
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        }),
        finalize(() => {
          this.triggerRefreshList();
        })
      );
  }

  callDeleteCandidate(candidateId: number): Observable<void | HttpErrorResponse> {
    return this.candidateApiService.addDeleteTagToCandidate(candidateId).pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('candidates.delete_toast', true));

        this.candidateHistoryService.refreshHistories(candidateId);
        this.refreshCandidateDetail(candidateId);
        this.refreshCandidateDocuments(candidateId);
        // refresh position list is called in component
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('candidates.delete_toast', false));

        return of(err);
      })
    );
  }

  callConvertCandidate(candidateId: number): Observable<void | HttpErrorResponse> {
    return this.candidateApiService.convertToCandidate(candidateId).pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('candidates.convert_toast', true));

        this.candidateHistoryService.refreshHistories(candidateId);
        this.refreshCandidateDetail(candidateId);
      }),
      catchError((err: MessageErrorResponse) => {
        if (!hasCommonError(err?.error?.errors)) {
          this.toast.showError(
            getErrorTranslationKey(
              err?.error?.errors,
              getGeneralMessage('candidates.convert_toast', false)
            )
          );
        }

        return of(err);
      })
    );
  }

  refreshCandidateDetail(candidateId: number, detail?: CandidateDetail) {
    if (detail) {
      this.setState({ candidateDetail: detail });
    } else {
      this.candidateApiService.getCandidateById(candidateId).subscribe((candidateDetail) => {
        this.setState({ candidateDetail });
      });
    }
  }

  callUploadDocument(
    candidateId: number,
    formData: FormData
  ): Observable<CandidateDocument | MessageErrorResponse> {
    return this.candidateApiService.uploadDocument(candidateId, formData).pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('candidates.upload_document', true));
        this.refreshCandidateDetail(candidateId);
        this.refreshCandidateDocuments(candidateId);
      }),
      catchError((err: MessageErrorResponse) => {
        if (!hasCommonError(err?.error?.errors)) {
          this.toast.showError(getGeneralMessage('candidates.upload_document', false));
        }
        return of(err);
      })
    );
  }

  private refreshCandidateDocuments(candidateId: number) {
    this.candidateApiService.getDocuments(candidateId).subscribe((documents) => {
      this.setState({
        candidateDocuments: documents,
      });
    });
  }

  openExportTableModal(): Observable<void> {
    return this.headerService.openExportDataModal(this.callExportTable.bind(this));
  }

  private callExportTable(): Observable<void | HttpResponse<Blob> | HttpErrorResponse> {
    const candidateFilter = this.getStateSnapshot().filters;
    const sortedFilters = removeBlankAttributes(candidateFilter);
    let encodedFilters: string | undefined;

    if (Object.keys(sortedFilters).length !== 0) {
      encodedFilters = encodeBase64(sortedFilters);
    }

    if (this.headerService.getStateSnapshot().filterTotalCount <= environment.exportCount) {
      return this.headerService.handleExportResponse(
        this.candidateApiService.exportCandidateTable(encodedFilters)
      );
    }

    return this.headerService.handleEmailExportResponse(
      this.candidateApiService.exportCandidateTableEmail(encodedFilters)
    );
  }

  getCandidateAttachedPositions(candidateId: number): Observable<CandidatePositionMinimal[]> {
    this.setState({ candidatePositionsForDocumentUploadLoading: true });

    return this.candidateApiService.getAllPositionsAttachedToCandidate(candidateId).pipe(
      catchError((err) => {
        this.toast.showError('candidates.attached_position_list_load_error');
        return throwError(err);
      }),
      finalize(() => this.setState({ candidatePositionsForDocumentUploadLoading: false }))
    );
  }

  callAttachToPosition(candidateId: number, positionId: number, type: UserPositionType) {
    return this.candidateApiService
      .attachToPosition(candidateId, positionId, type)
      .pipe(this.handleAttachDetachPositionSideEffects('candidates.position_attach', candidateId));
  }

  callDetachPosition(candidateId: number, positionId: number) {
    return this.candidateApiService
      .detachPosition(candidateId, positionId)
      .pipe(this.handleAttachDetachPositionSideEffects('candidates.position_detach', candidateId));
  }

  callUpdateCandidatePosition(
    positionId: number,
    optionSelected: Partial<MoveOrAddPositionDto>,
    candidateId?: number,
    userRoles?: MultiUserRole[]
  ): Observable<BoardCandidate | HttpErrorResponse> {
    const candidateDetail = this.getStateSnapshot().candidateDetail;
    const selectedColumn = this.positionState.getStateSnapshot().selectedKanbanBoardColumn;
    const modalType = getModalTypeUtil(userRoles ?? candidateDetail.roles);

    const dto: MoveOrAddPositionDto = {
      statusId: selectedColumn.id,
      statusOptionId: optionSelected.statusOptionId,
      feorNumber: optionSelected.feorNumber || null,
      membershipStartDate: optionSelected.membershipStartDate || null,
      subPositionId: optionSelected.subPositionId || null,
      guarantee: optionSelected.guarantee ?? null,
    };

    let updateFunction: UpdateFunctionType;

    if (modalType === ModalType.PhoneContact) {
      updateFunction = this.candidateBoardApiService.updatePhoneContactPosition.bind(
        this.candidateBoardApiService
      );
    } else {
      updateFunction = this.candidateBoardApiService.updateCandidatePosition.bind(
        this.candidateBoardApiService
      );
    }

    return updateFunction(positionId, candidateId ?? candidateDetail?.id, dto).pipe(
      tap(() => {
        this.toast.showSuccess(getGeneralMessage('candidates.position_update', true));

        this.candidateHistoryService.refreshHistories(candidateDetail?.id ?? candidateId);
        this.refreshCandidateDetail(candidateDetail?.id ?? candidateId);
        this.getAllPositionsForCardTable(candidateDetail?.id ?? candidateId).subscribe();
      }),
      catchError((err: HttpErrorResponse) => {
        this.toast.showError(getGeneralMessage('candidates.position_update', false));
        return of(err);
      })
    );
  }

  getAllPositionsForCardTable(candidateId: number): Observable<ListData<CandidatePosition>> {
    const { currentPage, perPage } = this.cardTableService.getStateSnapshot();

    return this.cardTableService.callGetItems<CandidatePosition, CandidatePositionCardTableRow>(
      'candidatePositionRows',
      this.candidateApiService.getCandidatePositions(candidateId, 1, (currentPage + 1) * perPage),
      this.mapCandidatePositionTableRows.bind(this)
    );
  }

  callLoadMorePositions(candidateId: number): Observable<ListData<CandidatePosition>> {
    return this.cardTableService.callLoadMoreItems<
      CandidatePosition,
      CandidatePositionCardTableRow
    >(
      candidateId,
      'candidatePositionRows',
      this.candidateApiService.getCandidatePositions.bind(this.candidateApiService),
      this.mapCandidatePositionTableRows.bind(this),
      'positions.more_positions_loaded'
    );
  }

  getRoleImage(
    detail: CandidateDetail | PhoneContactListItem | BoardCandidate | KanbanBoardTableItem
  ): RoleImageData {
    if (detail?.roles.includes({ name: UserRole.Candidate })) {
      return roleImagesConfig[UserRole.Candidate];
    } else if (detail?.roles) {
      return roleImagesConfig[detail.roles[0].name];
    }

    return {
      imageUrl: 'assets/image/person-placeholder.svg',
    };
  }

  getUserPositionTypeOptions(): UserPositionTypeOption[] {
    return [
      {
        name: TranslateInstance.instant('candidates.user_position_types.contact'),
        value: UserPositionType.Contact,
      },
      {
        name: TranslateInstance.instant('candidates.user_position_types.normal'),
        value: UserPositionType.Normal,
      },
    ];
  }

  private handleAttachDetachPositionSideEffects(toastText: string, candidateId: number) {
    return (
      source: Observable<PositionDetail | void>
    ): Observable<PositionDetail | void | HttpErrorResponse> => {
      return source.pipe(
        tap(() => {
          this.toast.showSuccess(getGeneralMessage(toastText, true));
          this.getAllPositionsForCardTable(candidateId).subscribe();
          this.candidateHistoryService.refreshHistories(candidateId);
        }),
        catchError((err: HttpErrorResponse) => {
          this.toast.showError(getGeneralMessage(toastText, false));
          return of(err);
        })
      );
    };
  }

  private mapCandidatePositionTableRows(
    positions: CandidatePosition[]
  ): CandidatePositionCardTableRow[] {
    const getIcon = (url: string | null): string | null => {
      if (!url) {
        return null;
      }

      return `${environment.assetUrl}/${url}`;
    };

    return positions.map((position) => {
      return {
        ...position,
        specializationIcon: getIcon(head<Specialization>(position.specializations)?.icon),
        partnerName: position.partner?.name,
        partnerLogo: position.partner?.logoThumb,
        applyDate: position.applyDate
          ? moment(position.applyDate).format(AppConstants.dateFormat)
          : '',
        userPosition: {
          ...position.userPosition,
          translatedType: TranslateInstance.instant(
            `candidates.user_position_types.${position.userPosition.type.toLowerCase()}`
          ),
        },
      };
    });
  }
}
